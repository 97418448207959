import {get} from "lodash";
import servicesUtils from "../../../utils/servicesUtils";
import queryString from "query-string";

const getCounts = (apiParams = {}) => {
  const queryParams = {
    dict_key: get(apiParams, ['additional', 'keys', 0, 'dict_key'], null),
    dict_values: get(apiParams, ['additional', 'keys', 0, 'dict_value'], null)
  }
  const qString = queryString.stringify(queryParams);

  return new Promise((resolve, reject) => {
      servicesUtils
        .postService("/patients/counts/?"+qString, apiParams)
        .then((data) => {
          resolve(data.data.count);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

export default getCounts;
